
export const NAV_OPTIONS = {
    "Admin" : "admin",
    "Auction" : "auction",
    "Public Goods" : "public",
    "NFTs"    : "nfts",
    "Contribute"  : "contribute"
};

export const ADMIN_ROUTES = [
    "Admin",
]

export const NAV_DEFAULT_LOCATION = NAV_OPTIONS.AUCTION;
